<template>
  <div>
    <v-row no-gutters class="px-5">
      <v-col class="px-4" cols="4">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <mf-text-input v-model="integration.job_id" disabled label="Job Id"></mf-text-input>
            </div>
          </template>
          <span>
            Id do job gerado pelo starlord
          </span>
        </v-tooltip>
      </v-col>
      <v-col class="px-4" cols="4">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <mf-text-input v-model="integration.config.host" :rules="[notEmptyRule]" label="Host"></mf-text-input>
            </div>
          </template>
          <span>
            Endereço do host do banco de dados de destino para realizar a query
          </span>
        </v-tooltip>
      </v-col>
      <v-col class="px-4" cols="4">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <mf-number-input v-model="integration.config.port" :rules="[notEmptyRule]" label="Porta"></mf-number-input>
            </div>
          </template>
          <span>
            Porta do banco de dados de destino
          </span>
        </v-tooltip>
      </v-col>
      <v-col class="px-4" cols="4">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <mf-text-input v-model="integration.config.user" :rules="[notEmptyRule]" label="Usuário"></mf-text-input>
            </div>
          </template>
          <span>
            Usuário para autenticação no banco destino
          </span>
        </v-tooltip>
      </v-col>
      <v-col class="px-4" cols="4">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <mf-text-input v-model="integration.config.password" :rules="[notEmptyRule]" label="Senha"></mf-text-input>
            </div>
          </template>
          <span>
            Senha para autenticação no banco destino
          </span>
        </v-tooltip>
      </v-col>
      <v-col class="px-4" cols="4">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <mf-text-input v-model="integration.config.database" :rules="[notEmptyRule]" label="Banco de dados"></mf-text-input>
            </div>
          </template>
          <span>
            Nome do banco de dados que será realizada a query
          </span>
        </v-tooltip>
      </v-col>
      <v-col class="px-4" cols="4">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <mf-select
                v-model="integration.config.driver"
                :rules="[notEmptyRule]"
                label="Driver"
                :items="['postgres', 'mssql', 'mysql', 'firebirdsql', 'oracle']"
              ></mf-select>
            </div>
          </template>
          <span>
            Driver para conexão com o banco de dados
          </span>
        </v-tooltip>
      </v-col>
      <v-col class="px-4" cols="4">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <base-auto-complete v-model="unit" item-text="formated_name" item-value="value" label="Lojas" :items="listUnit"></base-auto-complete>
            </div>
          </template>
          <span>
            Lista das lojas vinculadas a conta
          </span>
        </v-tooltip>
      </v-col>
      <v-col class="px-4" cols="4">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <mf-text-input v-model="integration.config.prefix" :rules="[notEmptyRule]" disabled label="Prefix"></mf-text-input>
            </div>
          </template>
          <span>
            Prefixo no bucket na cloud onde o arquivo de resultado será enviado <br />
            Prefixo Completo:<b>{{ integration.config.prefix }}</b>
          </span>
        </v-tooltip>
      </v-col>
      <v-col class="px-4" cols="4">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <mf-text-input v-model="integration.config.bucket" :rules="[notEmptyRule]" disabled label="Bucket"></mf-text-input>
            </div>
          </template>
          <span>
            Nome do bucket na cloud para onde o arquivo com o resultado da query será enviado
          </span>
        </v-tooltip>
      </v-col>
      <v-col class="px-4" cols="4">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-row no-gutters>
                <v-col>
                  <mf-text-input v-model="integration.cron_schedule" :rules="[notEmptyRule]" disabled label="Cron"></mf-text-input>
                </v-col>
                <v-col class="mt-3" cols="auto">
                  <v-icon color="primary" @click="dialog_cron = true">mdi-plus</v-icon>
                </v-col>
              </v-row>
            </div>
          </template>
          <span>
            Campo para configuração da cron
          </span>
        </v-tooltip>
      </v-col>
      <v-col v-if="edit" class="px-4" cols="4">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-row no-gutters>
                <v-col>
                  <mf-toggle v-model="integration.active" label="Ativo" color="#334A58"></mf-toggle>
                </v-col>
              </v-row>
            </div>
          </template>
          <span>
            Indica se a integração está ativa
          </span>
        </v-tooltip>
      </v-col>
      <v-col class="px-4" cols="10">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <label> SQL-Query </label>
              <mf-text-area-input v-model="integration.config.query" :rules="[notEmptyRule]"></mf-text-area-input>
            </div>
          </template>
          <span>
            Query a ser realizada no banco de dados destino
          </span>
        </v-tooltip>
      </v-col>
    </v-row>
    <dialog-cron v-model="dialog_cron" @confirmAction="SetCron"></dialog-cron>
  </div>
</template>

<script>
export default {
  components: {
    DialogCron: () => import('@/components/atomic-components/molecules/DialogCron.vue'),
    BaseAutoComplete: () => import('@/components/atomic-components/atoms/BaseAutoComplete.vue')
  },
  props: {
    integration: {
      type: Object,
      default: () => {}
    },
    edit: {
      type: Boolean,
      default: false
    },
    dataAccount: {
      type: Object
    },
    units: {
      type: Array
    }
  },
  data() {
    return {
      options: { mode: 'view', mainMenuBar: false },
      title: '',
      dialog_cron: false,
      notEmptyRule: v => !!v || 'Campo obrigatório',
      unit: ''
    }
  },
  computed: {
    listUnit() {
      if (this.units) {
        return this.units
      }
      return []
    }
  },
  watch: {
    unit() {
      if (this.unit !== '') {
        this.integration.config.prefix = 'ecommerce_sync/products/ecommerce_id_' + this.unit + '/complete'
      }
    }
  },
  mounted() {
    if (this.edit) {
      let unit = this.integration.config.prefix.split('/')[2].split('ecommerce_id_')[1]
      this.unit = this.listUnit.filter(item => item.value === unit)[0].value
    }
  },
  methods: {
    generateGenericToken() {
      return Math.random()
        .toString(36)
        .slice(-6)
    },
    SetCron(cron) {
      this.integration.cron_schedule = cron
      this.dialog_cron = false
    }
  }
}
</script>
