var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"px-5",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"px-4",attrs:{"cols":"4"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('mf-text-input',{attrs:{"disabled":"","label":"Job Id"},model:{value:(_vm.integration.job_id),callback:function ($$v) {_vm.$set(_vm.integration, "job_id", $$v)},expression:"integration.job_id"}})],1)]}}])},[_c('span',[_vm._v(" Id do job gerado pelo starlord ")])])],1),_c('v-col',{staticClass:"px-4",attrs:{"cols":"4"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('mf-text-input',{attrs:{"rules":[_vm.notEmptyRule],"label":"Host"},model:{value:(_vm.integration.config.host),callback:function ($$v) {_vm.$set(_vm.integration.config, "host", $$v)},expression:"integration.config.host"}})],1)]}}])},[_c('span',[_vm._v(" Endereço do host do banco de dados de destino para realizar a query ")])])],1),_c('v-col',{staticClass:"px-4",attrs:{"cols":"4"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('mf-number-input',{attrs:{"rules":[_vm.notEmptyRule],"label":"Porta"},model:{value:(_vm.integration.config.port),callback:function ($$v) {_vm.$set(_vm.integration.config, "port", $$v)},expression:"integration.config.port"}})],1)]}}])},[_c('span',[_vm._v(" Porta do banco de dados de destino ")])])],1),_c('v-col',{staticClass:"px-4",attrs:{"cols":"4"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('mf-text-input',{attrs:{"rules":[_vm.notEmptyRule],"label":"Usuário"},model:{value:(_vm.integration.config.user),callback:function ($$v) {_vm.$set(_vm.integration.config, "user", $$v)},expression:"integration.config.user"}})],1)]}}])},[_c('span',[_vm._v(" Usuário para autenticação no banco destino ")])])],1),_c('v-col',{staticClass:"px-4",attrs:{"cols":"4"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('mf-text-input',{attrs:{"rules":[_vm.notEmptyRule],"label":"Senha"},model:{value:(_vm.integration.config.password),callback:function ($$v) {_vm.$set(_vm.integration.config, "password", $$v)},expression:"integration.config.password"}})],1)]}}])},[_c('span',[_vm._v(" Senha para autenticação no banco destino ")])])],1),_c('v-col',{staticClass:"px-4",attrs:{"cols":"4"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('mf-text-input',{attrs:{"rules":[_vm.notEmptyRule],"label":"Banco de dados"},model:{value:(_vm.integration.config.database),callback:function ($$v) {_vm.$set(_vm.integration.config, "database", $$v)},expression:"integration.config.database"}})],1)]}}])},[_c('span',[_vm._v(" Nome do banco de dados que será realizada a query ")])])],1),_c('v-col',{staticClass:"px-4",attrs:{"cols":"4"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('mf-select',{attrs:{"rules":[_vm.notEmptyRule],"label":"Driver","items":['postgres', 'mssql', 'mysql', 'firebirdsql', 'oracle']},model:{value:(_vm.integration.config.driver),callback:function ($$v) {_vm.$set(_vm.integration.config, "driver", $$v)},expression:"integration.config.driver"}})],1)]}}])},[_c('span',[_vm._v(" Driver para conexão com o banco de dados ")])])],1),_c('v-col',{staticClass:"px-4",attrs:{"cols":"4"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('base-auto-complete',{attrs:{"item-text":"formated_name","item-value":"value","label":"Lojas","items":_vm.listUnit},model:{value:(_vm.unit),callback:function ($$v) {_vm.unit=$$v},expression:"unit"}})],1)]}}])},[_c('span',[_vm._v(" Lista das lojas vinculadas a conta ")])])],1),_c('v-col',{staticClass:"px-4",attrs:{"cols":"4"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('mf-text-input',{attrs:{"rules":[_vm.notEmptyRule],"disabled":"","label":"Prefix"},model:{value:(_vm.integration.config.prefix),callback:function ($$v) {_vm.$set(_vm.integration.config, "prefix", $$v)},expression:"integration.config.prefix"}})],1)]}}])},[_c('span',[_vm._v(" Prefixo no bucket na cloud onde o arquivo de resultado será enviado "),_c('br'),_vm._v(" Prefixo Completo:"),_c('b',[_vm._v(_vm._s(_vm.integration.config.prefix))])])])],1),_c('v-col',{staticClass:"px-4",attrs:{"cols":"4"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('mf-text-input',{attrs:{"rules":[_vm.notEmptyRule],"disabled":"","label":"Bucket"},model:{value:(_vm.integration.config.bucket),callback:function ($$v) {_vm.$set(_vm.integration.config, "bucket", $$v)},expression:"integration.config.bucket"}})],1)]}}])},[_c('span',[_vm._v(" Nome do bucket na cloud para onde o arquivo com o resultado da query será enviado ")])])],1),_c('v-col',{staticClass:"px-4",attrs:{"cols":"4"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('mf-text-input',{attrs:{"rules":[_vm.notEmptyRule],"disabled":"","label":"Cron"},model:{value:(_vm.integration.cron_schedule),callback:function ($$v) {_vm.$set(_vm.integration, "cron_schedule", $$v)},expression:"integration.cron_schedule"}})],1),_c('v-col',{staticClass:"mt-3",attrs:{"cols":"auto"}},[_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){_vm.dialog_cron = true}}},[_vm._v("mdi-plus")])],1)],1)],1)]}}])},[_c('span',[_vm._v(" Campo para configuração da cron ")])])],1),(_vm.edit)?_c('v-col',{staticClass:"px-4",attrs:{"cols":"4"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('mf-toggle',{attrs:{"label":"Ativo","color":"#334A58"},model:{value:(_vm.integration.active),callback:function ($$v) {_vm.$set(_vm.integration, "active", $$v)},expression:"integration.active"}})],1)],1)],1)]}}],null,false,2493952644)},[_c('span',[_vm._v(" Indica se a integração está ativa ")])])],1):_vm._e(),_c('v-col',{staticClass:"px-4",attrs:{"cols":"10"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('label',[_vm._v(" SQL-Query ")]),_c('mf-text-area-input',{attrs:{"rules":[_vm.notEmptyRule]},model:{value:(_vm.integration.config.query),callback:function ($$v) {_vm.$set(_vm.integration.config, "query", $$v)},expression:"integration.config.query"}})],1)]}}])},[_c('span',[_vm._v(" Query a ser realizada no banco de dados destino ")])])],1)],1),_c('dialog-cron',{on:{"confirmAction":_vm.SetCron},model:{value:(_vm.dialog_cron),callback:function ($$v) {_vm.dialog_cron=$$v},expression:"dialog_cron"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }